import React from "react";
import Loading from "./Loading"
import { toppings, lifespanMinutes, adjectives, titles } from "../pizza_data";

class Piedentity extends React.Component {
    nameRef = React.createRef();

    state = {
        age: null,
        gender: null,
        country: null,
        pizzaToppings: [],
        pizzaName: null,
        pizzaAge: null,
        loading: false,
        hasResults: false,
    };

    agifyUrl = "https://api.agify.io/?name="
    nationalizeUrl = "https://api.nationalize.io?name="

    async fetchAge(name) {
        await fetch(this.agifyUrl + name)
            .then(res => res.json())
            .then(json => this.setState({ age: json.age }))
            .catch(err => console.log(err))
    }

    async fetchCountry(name) {
        await fetch(this.nationalizeUrl + name)
            .then(res => res.json())
            .then(json => {
                if(json.country.length > 0) {
                    this.setState({ country: json.country[0].country_id })
                }
            })
            .catch(err => console.log(err))
    }

    calculatePizzaAge(age) {
        const ageInMinutes = (((age * 365) * 24) * 60)
        return Math.floor(ageInMinutes / lifespanMinutes)
    }

    getPizzaName(topping, age, name) {
        const title = titles[name.length % 10]
        const adjKeys = Object.keys(adjectives.syllables).reverse()
        const combinedAdj = adjKeys.flatMap((key) => {
            return adjectives.syllables[key]
        })
        const adj = combinedAdj[age % 10]
        console.log(age %  10)
        return ['The', title, adj, topping].join(' ')
    }

    determineFavoriteToppings(country) {
        if(!country || !country.length > 0) return ['potato', 'extra cheese']
        let result = 0
        let idx = 0
        for(idx; idx < country.length; idx++) {
            result += country.charCodeAt(idx) % 10
        }
        return [toppings[result+2], toppings[result-2]]
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ loading: true })
        
        const name = this.nameRef.current.value;
        
        await Promise.all([
            this.fetchAge(name),
            this.fetchCountry(name)
        ]);

        const favToppings = this.determineFavoriteToppings(this.state.country)
        
        this.setState({
            pizzaAge: this.calculatePizzaAge(this.state.age),
            pizzaName: this.getPizzaName(favToppings[0], this.state.age, name),
            pizzaToppings: favToppings,
            loading: false,
            hasResults: true,
        });
    }

    render() {
        const pizzaName = <div className="piedentiy-result-item">
            Your Pizza Name is:&nbsp;
            <span className="highlight">{this.state.pizzaName}</span>
        </div>

        const pizzaAge = <div className="piedentiy-result-item">
            Age in Pizza Years:&nbsp;
            <span className="highlight">{this.state.pizzaAge} years</span>*
        </div>

        const favoritePizza = <div className="piedentiy-result-item">
            Your favorite pizza is <span className="highlight">{this.state.pizzaToppings[0]}</span> with <span className="highlight">{this.state.pizzaToppings[1]}</span>.
        </div>
        
        const disclaimer = <div className="disclaimer">*estimated based on average pizza life span of 75 minutes.</div>
        
        const piedentity_result = <div hidden={!this.state.hasResults} className="piedentity-result">
            {pizzaName}
            {pizzaAge}
            {favoritePizza}
            {disclaimer}
        </div>

        return <div className="wrap">
            <form className="piedentity-form" onSubmit={this.handleSubmit}>
                <input required placeholder="enter your name" ref={this.nameRef} />
                <button type="submit">calculate my piedentity</button>
            </form>
            {this.state.loading ? <Loading message = "Calculating Piedentity..." /> : piedentity_result}
        </div>
    };
}

export default Piedentity;