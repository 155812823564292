import React from "react";
import { pizza } from "../helpers";

const Loading = props => (
    <div className="loading-container">
        <h1>{props.message || 'Loading...' }</h1>
        <div className="loading">{pizza}</div>
    </div>
);

export default Loading;