export const toppings = [
    'anchovy',
    'bacon',      
    'basil',
    'bbq sauce',
    'capsicum',
    'pineapple',  
    'eggplant',
    'extra cheese',
    'hotdog',
    'jalapeño',
    'mozzarella', 
    'mushroom',   
    'olive',      
    'potato',     
    'caramelised onion',
    'pepperoni',
    'red onion',    
    'roasted garlic',
    'tomato',
    'sausage',    
]

export const lifespanMinutes = 75;

export const nouns = {
    syllables: {
        1: ['cheese', 'crust', 'sauce', 'pie', 'herbs', 'dough'],
        2: ['pizza', 'eggplant', 'mushroom', 'toppings', 'spices', 'flavour', 'olive', 'garlic', 'onion', 'sausage', 'bacon', 'basil'],
        3: ['anchovy', 'capsicum', 'potato', 'pineapple', 'tomato', 'artichoke'],
        4: ['pepperoni', 'oregano', 'mozzarella', 'jalapeño']
    }
};

export const adjectives = {
    syllables: {
        2: ['crispy', 'lovely', 'cheesy', 'fluffy', 'doughy', 'crusty', 'crunchy', 'dreamy', 'tasty', 'yummy', 'tempting', 'greasy', 'subtle', 'super', 'spicy', 'fragrant', 'tangy'],
        3: ['savoury', 'delicious', 'flavourful', 'cohesive', 'flavourful', 'nutritious'],
    }
};

export const phrases = {
    title: ['without definition', 'knows no limits', 'without restraint', 'of much admiration', 'to the limit', 'for the ages', 'of temptation', 'of the soul', 'to die for', 'of desire'],
    syllables: {
        2: ['oh my', 'how nice', 'I love', 'and more', 'so good', 'hell yeah', 'so fresh', 'my friend'],
        3: ['in my heart', 'on my mind', 'my true love', 'forever', 'I love thee', 'I follow', 'ooh la la', 'my lover', 'I love you', 'so damn good', 'the cat\'s meow', 'the bee\'s knees', 'yes, yes, yes'],
    }
};

export const punctuation = {
    joining: [';', ',', '—'],
    ending: ['!', '...', '?']
};

export const titles = [
    'great', 'much too', 'wonderful', 'marvelous', 'magnificent', 'royal', 'fantastic', 'very', 'wacky', ''
]