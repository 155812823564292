import React from "react";
import Loading from "./Loading"
import { titleCase, dateFormat } from "../helpers";

class News extends React.Component {
    state = {
        stories: {},
        loading: true,
        failed: false,
    };

    pizzaNewsUrl = 'https://api.currentsapi.services/v1/search?' +
        'apiKey=zHOeCTZjGA10MFXHkfZubMWQctzvST66gZEaoFFNLoCJWZQ6' +
        '&keywords=pizza'

    async componentDidMount() {
        await fetch(this.pizzaNewsUrl)
            .then(res => res.json())
            .then(json => this.setState({ stories: json.news }))
            .catch(err => {
                console.log(err)
                this.setState({ failed: true })
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderStory = key => {
        const story = this.state.stories[key]
        
        // let storyImage
        // if(story.image && story.image !== "None") {
        //     storyImage = <div className="story-image-container">
        //         <img className="story-image" alt={story.title} src={story.image} />
        //     </div>
        // }

        return (
            <li key={key} className="story">
                <a className="story-title" href={story.url}>{story.title}</a>
                <div className="story-details">
                    <span className="story-author">{titleCase(story.author)}</span>
                    <span className="seperator">|</span>
                    <span className="story-date">{dateFormat(story.published)}</span>
                </div>
                <div className="story-content">
                    {/* {storyImage} */}
                    <div className="story-desc">{story.description}</div>
                </div>
            </li>
        );
    }

    render() {
        const storyKeys = Object.keys(this.state.stories)

        let stories
        if(this.state.failed)
            stories = "There was a problem fetching stories!"
        else
            stories = <ul className="stories">
                {storyKeys.map(this.renderStory)}
            </ul>
        
        return <div className="wrap">
            {this.state.loading ? <Loading message="loading the latest pizza news..." /> : stories}
        </div>
    };
}

export default News;