import React from "react";
import { random, titleCase } from "../helpers";
import { nouns, adjectives, phrases, punctuation } from "../pizza_data";

class Pieku extends React.Component {
    state = {
        haikuTitle: null,
        haikuLine1: null,
        haikuLine2: null,
        haikuLine3: null,
    };
    
    componentWillMount() {
        this.updateHaiku()
    }

    updateHaiku = () => {
        const titleNoun = random(nouns.syllables[1]);
        this.setState({
            haikuTitle: titleCase(`${titleNoun} ${random(phrases.title)}`),
            haikuLine1: `${random(nouns.syllables[1])}, ${random(nouns.syllables[1])}, ${random(phrases.syllables[3])}${random(punctuation.ending)}`,
            haikuLine2: `${random(adjectives.syllables[3])} ${random(nouns.syllables[2])}${random(punctuation.joining)} ${random(phrases.syllables[2])}`,
            haikuLine3: `${random(adjectives.syllables[2])}${random(punctuation.joining)} ${random(adjectives.syllables[2])} ${titleNoun}${random(punctuation.ending)}`,
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.updateHaiku()
    };

    render() {
        return (
            <div className="wrap">
                <div className="pieku-result">
                    <div className="haiku-title-container">
                        <span className="haiku-title">{this.state.haikuTitle}</span>
                    </div>
                    <div className="haiku-lines">
                        <div className="haiku-line">{this.state.haikuLine1}</div>
                        <div className="haiku-line">{this.state.haikuLine2}</div>
                        <div className="haiku-line">{this.state.haikuLine3}</div>
                    </div>
                </div>
                <form className="pieku-form" onSubmit={this.handleSubmit}>
                    <button type="submit">gimme another one...</button>
                </form>
            </div>
        );
    };
}

export default Pieku;