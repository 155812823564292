import React from "react";

const Header = props => (
    <header>
        <nav className="nav">
            <a className="title" href="/">{props.title}</a>
            <a className="link" href="/news">news</a>
            <a className="link" href="/piedentity">piedentity</a>
            <a className="link" href="/pieku">pieku</a>
        </nav>
    </header>
);

export default Header;