import React from "react";

export function dateFormat(str) {
    const dateTimeParts = str.split(' ')
    return new Date(dateTimeParts[0]).toLocaleDateString()
}

export function titleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function random(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

export const pizza = <span role="img" aria-label="jsx-a11y/accessible-emoji">🍕</span>
