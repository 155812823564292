import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import News from "./News";
import Home from "./Home";
import Header from "./Header";
import NotFound from "./NotFound";
import Piedentity from "./Piedentity";
import Pieku from "./Pieku";


const Router = () => (
  <BrowserRouter>
    <Header title="PIZZA BOX" />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/news" component={News} />
      <Route exact path="/piedentity" component={Piedentity} />
      <Route exact path="/pieku" component={Pieku} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;