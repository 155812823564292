import React from "react";
import { pizza } from '../helpers'

class Home extends React.Component {
    render() {
        return (
            <div className="home wrap">
                <h1>Welcome to the pizza party.</h1>
                <h1>Grab a slice!</h1>
                <ul>
                    <li>{pizza} Read the latest in <a href="/news">pizza news</a>!</li>
                    <li>{pizza} Find out your <a href="/piedentity">true piedentity</a>.</li>
                    <li>{pizza} Relax with a <a href="/pieku">beautiful pieku</a>.</li>
                </ul>
            </div>
        );
    };
}

export default Home;